import React from 'react'
import Layout from '../components/_App/Layout'
import Seo from '../components/_App/Seo'
import NavbarTwo from '../components/_App/NavbarTwo'
import PageBanner from '../components/Common/PageBanner'
import TeamTwo from '../components/Team/TeamTwo'
import TestimonialsTwo from '../components/Common/TestimonialsTwo'
import Partner from '../components/Common/Partner'
import Footer from '../components/_App/Footer'
import Copyright from '../components/_App/Copyright'

const TeamPage = () => {
    return (
        <Layout>
            <Seo title="Team" />

            <NavbarTwo />

            <PageBanner 
                pageTitle="Our Team Member" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Our Team" 
            />

            <TeamTwo />

            <div className="testimonial-4">
                <TestimonialsTwo />
            </div>

            <Partner />

            <Footer />

            <Copyright />
        </Layout>
    )
}

export default TeamPage
